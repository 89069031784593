footer {
    background-color: #212529;
    position: relative;
}

.main-footer .footer-content {
    width: 356px;
    color: #B4B4B4;
    font-size: 16px;
}

.main-footer {
    display: flex;
    padding: 100px 20px;
}

.main-footer .footer-content p {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
}

.main-footer ul.footer-nav {
    list-style: none;
}

.main-footer a.footer-link {
    color: #B4B4B4;
    text-decoration: none;
    line-height: 50px;
    font-size: 18px;
}

.main-footer .logo-footer {
    width: 40%;
    padding-right: 35px;
    position: relative;
}

.main-footer .programs-footer {
    width: 26%;
    padding: 0px 35px 0px 15px;
}

.main-footer .about-footer {
    width: 18%;
    padding: 0px 35px 0px 35px;
}

.main-footer .feature-footer {
    width: 31%;
    /* padding-left: 35px; */
}

.head-title h4 {
    text-transform: uppercase;
    margin: 0px 0px 6px 0px;
}

.main-footer .divider {
    width: 85px;
    height: 4px;
    background-color: #FB4F22;
    border-radius: 4px;
    margin-bottom: 15px;
}

.main-footer li.social-item+li {
    margin-left: 16px;
}

.main-footer ul.social-nav {
    list-style: none;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
}

.main-footer i.fa.fa-facebook-f {
    font-size: 25px;
    color: #000;
    line-height: 39px;
    width: 34px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
}

.main-footer i.fa {
    color: #fff;
    font-size: 30px;
}

.main-footer i.fa.fa-linkedin {
    font-size: 25px;
    color: #000;
    line-height: 35px;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
}

.footer {
    background-color: #FB4F22;
}

.inner-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
}

.copyright p {
    font-size: 14px;
    color: #fff;
    font-weight: 800;
    line-height: 30px;
}

ul.terms-nav {
    display: flex;
    list-style: none '';
}

a.terms-link {
    text-decoration: none;
    font-size: 14px;
    line-height: 30px;
    font-weight: 800;
    color: #fff;
}

li.terms-item+li {
    padding-left: 20px;
}

.logo-footer:after {
    content: " ";
    width: 490px;
    height: 150px;
    background-image: url("/public/assets/images/footer-img.png");
    position: absolute;
    bottom: -100px;
    left: -100px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 100%;
}

i.fa.fa-facebook-f:hover,
i.fa.fa-linkedin:hover {
    background-color: #FB4F22;
}

i.fa.fa-twitter:hover,
i.fa.fa-instagram:hover {
    color: #FB4F22;
}

@media screen and (max-width: 1440px) {
    .main-footer .programs-footer {
        padding: 0px 35px 0px 0px;
    }

    .main-footer .about-footer {
        padding: 0px 15px 0px 15px;
    }

    .main-footer i.fa.fa-facebook-f {
        font-size: 24px;
        line-height: 38px;
        width: 30px;
        height: 30px;
    }

    .main-footer i.fa.fa-linkedin {
        font-size: 20px;
        line-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 7px;
    }

    .main-footer a.footer-link {
        line-height: 40px;
        font-size: 16px;
    }

    .main-footer .footer-content p {
        font-size: 14px;
    }

    .main-footer {
        padding: 80px 0px;
    }

    .copyright p {
        font-size: 12px;
        line-height: 20px;
    }

    a.terms-link {
        font-size: 12px;
        line-height: 20px;
    }



}

@media screen and (max-width: 1024px) {
    .main-footer {
        display: grid;
        padding: 80px 20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .main-footer .logo-footer {
        width: 100%;
        padding: 0 0 40px;
    }

    .main-footer .about-footer {
        width: 100%;
        padding: 0px 40px 0px 0px;
    }

    .main-footer .programs-footer {
        width: 100%;
        padding: 0 0 0 40px;
    }

    .main-footer .feature-footer {
        width: 100%;
        /* padding-left: 40px; */
    }

    .main-footer .footer-content {
        width: 100%;
        font-size: 16px;
    }

    .logo-footer:after {
        width: 400px;
        height: 130px;
        bottom: -325px;
        left: -90px;
    }

    .inner-footer {
        padding: 8px 20px;
    }

    h4 {
        font-size: 18px;
    }

    .footer .copyright p {
        font-size: 12px;
        color: #fff;
        font-weight: 800;
        line-height: 30px;
    }

    .footer a.terms-link {
        text-decoration: none;
        font-size: 12px;
        line-height: 30px;
        font-weight: 800;
        color: #fff;
    }

}

@media screen and (max-width: 767px) {
    h4 {
        font-size: 16px;
    }
    .logo-footer:after {
        width: 355px !important;
        height: 110px !important;
     
    }
    .main-footer {
        display: grid;
        padding: 60px 20px 100px;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .main-footer .footer-content p {
        font-size: 14px;
    }

    .site-logo img {
        width: 120px;
    }

    .main-footer .programs-footer,
    .main-footer .feature-footer {
        padding: 0px;
    }

    .main-footer a.footer-link {
        line-height: 2.3;
        font-size: 16px;
    }

    .logo-footer:after {
        display: none;
        bottom: -735px;
        left: -80px;
    }

    .main-footer i.fa {
        font-size: 24px;
    }

    .main-footer i.fa.fa-facebook-f {
        font-size: 20px;
        line-height: 32px;
        width: 24px;
        height: 24px;
    }

    .main-footer i.fa.fa-linkedin {
        font-size: 18px;
        line-height: 24px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
    }

    .main-footer .logo-footer {
        padding: 0 0 20px;
    }

    .footer .copyright p {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.3;
        padding-top: 10px;
    }

    .inner-footer {
        display: flex;
        flex-direction: column-reverse;
    }

    .footer a.terms-link {
        font-size: 12px;
        line-height: 1.2;
        font-weight: 500;
    }
}