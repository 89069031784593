/* header css */
header {
    position: relative;
    width: 100%;
    top: 0;
    /* background: linear-gradient(180deg, rgba(0, 132, 160, 1) 35%, rgba(0, 127, 151, 0.6474964985994398) 65.5%, rgba(0, 119, 132, 0) 100%); */
    z-index: 99;
    background: linear-gradient(180deg, #382B28 0%, #382b2880 70%, #382c2900 100%);
}

.main-header {
    background-color: #000;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    padding: 15px 55px;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    
}



.main-header.scrolled {
    background-color: #000;
}

.site-logo {
    width: 20%;
}

.header-btn {
    margin-left: 40px;
}

.site-logo img {
    width: 170px;
}


button.position-relative.menu_toggle.brgr_btn {
    display: none;
}
.navbar {

    display: flex;
    align-items: center;
}

ul.navbar-nav {
    display: flex;
    list-style: none;
}

li.navbar-item {
    margin: 0px 17px;
}

a.nav-link {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}




.main-header .navbar .flap-navbar a {
    color: #fff !important;
    font-size: 18px !important;
    font-family: Euclid Circular A !important;
    padding: 5px;
    font-weight: 500;
    line-height: 1;
}

.main-header .navbar .flap-navbar li+li {
    margin-left: 30px;
}

/* .main-header .navbar .flap-navbar li:last-child a {
    background-color: #FB4F22;
    padding: 15px 40px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    border: none;
} */

button.join {
    background-color: #FB4F22;
    padding: 15px 59px;
    color: #fff;
    border-radius: 23px;
    font-size: 18px;
    border: none;
}

.main-banner {
    width: 100%;
    position: relative;
}

.main-banner img {
    width: 100%;
    max-height: 885px;
}


.main-header .navbar .flap-navbar li.dropdown ul li+li {
    margin: 5px 0px 0px;
}
.main-header .navbar .flap-navbar li.dropdown ul li a {
    color: #000 !important;
    font-size: 16px !important;
    padding: 10px 15px !important;
    background-color: transparent;
    border-radius: 0;
}
.main-header .navbar .flap-navbar li.dropdown ul {
    padding: 10px 10px 20px;
    top: 40px;
    left: 0px;
}
.main-header .navbar .flap-navbar li.dropdown .dropdown-indicator {
    background-image: url("/public/assets/images/menuDropdown.svg");  
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
    top: 2px;
    position: relative;
}
.main-header .navbar .flap-navbar li.dropdown ul li:hover a {
    color: #fb4f22 !important;
}










@media screen and (max-width: 1440px) {

    .main-header {
        padding:  20px;
    }
}





/* 
@media (min-width: 1025px) {

    i.fi-list {
        display: none;
      
    }

    .flap-navbar ul {
        display: block !important;
        margin: 0;
        padding: 0;
        display: flex !important;
        list-style: none !important;
        align-items: center !important;
    }

 

    button.position-relative.menu_toggle.brgr_btn {
        display: none;
    }
   


    .main-header .navbar .flap-navbar li.dropdown ul{

        left: 0;
        padding: 10px 10px 20px;
        top: 40px;
    }
    .flap-navbar .dropdown ul li {
        min-width: 202px;
    }
  
}
 */





@media screen and (max-width: 1024px) {

    button.position-relative.menu_toggle.brgr_btn {
        display: block;
    }

    button.menu_close.close_btn {
        z-index: 99999;
        position: absolute;
        right: 20px;
        top: 30px;
    }



    .site-logo img {
        width: 140px;
    }

    .site-logo {
        width: 50%;
    }
    .header-btn {
        position: absolute;
        margin-left: 0px;
        right: 53px;
       
    }
    .main-header .navbar .mobile-flap-nav-toggle {
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-image: url("../../../../public/assets/images/menuBar.svg");
    }

    .main-header .navbar .flap-nav-container div.position-relative {
        line-height: 0;
    }

    .mobile-flap-nav-active .flap-navbar>ul {
        list-style: none;
        margin: 0px;
        padding: 80px 40px 40px;
        position: absolute;
        inset: initial;
        display: inline-block !important;
        right: 0px;
        top: 0px;
        width: 50%;
        height: 100vh;
        border-radius: 0;
    }

    .main-header .navbar .mobile-flap-nav-toggle.fi-x {
        background-image: url("../../../../public/assets/images/closeMenu.svg");
    }

    .mobile-flap-nav-active .main-header .navbar .flap-navbar>ul>li a {
        color: #002147 !important;
        padding: 10px 30px;
    }

    .mobile-flap-nav-active .main-header .navbar .flap-navbar>ul>li+li {
        margin: 30px 0px 0px;
    }

    /* .mobile-flap-nav-active .main-header .navbar .flap-navbar>ul>li:last-child a {
        background-color: #FB4F22;
        padding: 15px 40px;
        color: #fff !important;
        border-radius: 50px;
        font-size: 18px;
        border: none;
        display: inline-block;
    } */

    .header-btn {
        position: absolute;
        margin-left: 0px;
        right: 53px;
        font-size: 14px;
        bottom: 17px;
    }


    button.menu_toggle{
        background-image: url(https://musite.b-cdn.net/videos/burgr.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 38px;
        width: 34px;
        position: absolute;
        
        top: 31%;
        right: 20px;
        border-radius: 4px;
        border: none;

    }

    button.menu_close {
        background-image: url(https://musite.b-cdn.net/videos/close.svg);        
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: none;
        background-size: 110%;
        background-position: center center;
    }


    .headerBtn{
        margin-bottom: 0px;
        margin-right: 13px;
    }
    .mobile-flap-nav-active .flap-navbar>ul {

        width: 100%;
    }

    .main-header .navbar .flap-navbar a {
        font-size: 16px !important;
    }
    .navbar{
        flex-direction: row-reverse;
    }
    .mobile-flap-nav-active .main-header .navbar .flap-navbar>ul>li a{
        padding-left: 0px;
        padding-right: 0px;
    }
    .main-header .navbar .flap-navbar li.dropdown ul {
        margin: 10px 0px 0px 0px;
    }
    .main-header .navbar .flap-navbar li.dropdown .dropdown-indicator{
        filter: contrast(0);
    }

}


@media screen and (max-width: 767px) {
    .button.menu_toggle{
        top: 22%;
    }
    /* .pastProgVideoSec .swiper-slide{
        width: 394px !important;
    } */
    /* .pastProgVideoSec .swiper-slide.swiper-slide-active{
        width: 394px !important;
    } */
}



@media screen and (max-width: 767px) {

    button.menu_toggle{
        background-image: url(https://musite.b-cdn.net/videos/burgr.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 36px;
        width: 34px;
        position: absolute;
        top: 31%;
        right: 20px;
        border-radius: 4px;
        border: none;

    }
    .header-btn{
        bottom: 14px;
    }
}